<template>
  <div class="login">
    <zg-login ref="login" :isModal="false" :visible="true"></zg-login>
    <div class="tips">
      <h3>智能推广型CRM技术提供商</h3>
      <h4>Copyright 2020 sjqz.cc All Rights Reserved.  京 ICP备2021007986号</h4>
    </div>
  </div>
</template>

<script>
import Login from '../components/Logon'
export default {
  data(){
    return {
      
    }
  },
  mounted(){

  },
  methods: {
    
  },
  components: {
    'zg-login': Login
  }
}
</script>

<style lang="scss" scoped>
.login{
  background-color: #1a9afd;
  width: 100%;
  height: 100%;
  background: url('https://image.qll-times.com/2020/06/a932e858230742b5a759606bf2b95a5a.jpg') no-repeat 59%/cover transparent;
  &:after{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(60, 85, 167, 0.65);
    content: "";
  }
}
h3, h4{
  color: #fff;
  font-size: 14px;
  text-align: center;
}
.tips{
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
</style>